import { Component, OnInit, Type } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialAuthService } from 'angularx-social-login';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { GetOfferComponent } from 'src/app/shared/get-offer/get-offer.component';
import { LoginSignupDialogComponent } from '../login-signup-dialog/login-signup-dialog.component';
const MODALS: { [name: string]: Type<any> } = {
  focusFirst: LoginSignupDialogComponent,
  focusSecond: GetOfferComponent,
};
@Component({
  selector: 'app-special-offers',
  templateUrl: './special-offers.component.html',
  styleUrls: ['./special-offers.component.css'],
})
export class SpecialOffersComponent implements OnInit {
  currentSection = 'offer';
  showFreeMatrial: boolean = false;
  isLoading: boolean = false;
  userDetails: any;
  constructor(
    private spinner: NgxSpinnerService,
    public as: AppService,
    public routes: ActivatedRoute,
    private router: Router,
    private _modalService: NgbModal,
    private authService: SocialAuthService,
    private firestore: AngularFirestore,
    private toastr: ToastrService
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('keeelWebUser'));
  }
  openLink() {
    window
      .open('https://surveyheart.com/form/6139ea79f9595f3d1f97ea91', '_blank')
      .focus();
  }
  ngOnInit(): void {
    if (window.location.href.includes('=')) {
      this.as.calledFrom = window.location.href.split('=')[1];
    }
    // if (this.as.calledFrom != null && this.userDetails!=null) {
    //   this.router.navigate(['pricing'],);
    // }
  }

  /**
   * Window scroll method
   */
  windowScroll() {
    const navbar = document.getElementById('navbar');
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop > 50
    ) {
      navbar.classList.add('nav-sticky');
    } else {
      navbar.classList.remove('nav-sticky');
    }
  }

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  /**
   * Toggle navbar
   */
  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }

  gotoNext() {
    this.as.selectedBoard = 'Select Board';
    this.as.selectedClass = 'Select Class';
    this.as.selectedSubjectOrTopic = 'Select Subject/Topic';
    this.as.listOfFiles = [];
    this.as.subjectOrTopicNameList = [];
  }
  openSecond(name: string) {
    const modalRef = this._modalService.open(MODALS[name]);
    modalRef.result.then(
      async (data: any) => {
        if (data) {
          // console.log(data);
        } else {
        }
      },
      (reason: any) => {}
    );
  }
  open(name: string) {
    this.gotoNext();
    if (this.userDetails != null) {
      this.userDetails = null;
      localStorage.removeItem('keeelWebUser');
      this.authService.signOut();
      location.reload();
    } else {
      // this.signInWithGoogle();
      const modalRef = this._modalService.open(MODALS[name]);
      modalRef.result.then(
        async (data: any) => {
          if (data) {
            // console.log(data);

            this.getDataAfterLogin(data['email'], data['toInsert']);
          } else {
          }
        },
        (reason: any) => {}
      );
    }
  }
  getDataAfterLogin(email, toInsert): void {
    try {
      // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((verifiedUser) => {
      //   console.log(verifiedUser);
      const data = this.firestore.collection('users', (ref) =>
        ref.where('email', '==', email)
      );
      data.get().subscribe(async (val) => {
        if (val.docs.length > 0) {
          localStorage.setItem(
            'keeelWebUser',
            JSON.stringify({
              userId: val.docs[0].id,
              name: val.docs[0].data()['displayName'],
              email: val.docs[0].data()['email'],
              mobileNo: val.docs[0].data()['phoneNumber'],
              board: val.docs[0].data()['board'],
              duration: val.docs[0].data()['duration'],
              plan: val.docs[0].data()['plan'],
              class: val.docs[0].data()['standard'],
            })
          );

          switch (val.docs[0].data()['board']) {
            case 'ICSE':
              this.as.selectedBoard = 'ICSE/ISC';
              break;
            case 'SSC':
              this.as.selectedBoard = 'SSC/HSC';
              break;
            case 'CBSE':
              this.as.selectedBoard = 'CBSE';
              break;
            case 'OTHERBOARD':
              this.as.selectedBoard = 'OTHER BOARD';
              break;
            default:
              break;
          }
          this.as.selectedClass = val.docs[0].data()['standard'];
          this.userDetails =
            localStorage.getItem('keeelWebUser') == ''
              ? null
              : JSON.parse(localStorage.getItem('keeelWebUser'));
          if (this.as.calledFrom != null) {
            this.router.navigate(['pricing']);
          } else {
            location.reload();
          }
        }
      });
      // }
      // );
    } catch (error) {
      console.log(error);
    }
  }
  showSuccessToaster(message, title) {
    this.toastr.error(message, title);
  }
  showErrorToaster(message, title) {
    this.toastr.error(message, title);
  }
}
