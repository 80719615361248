<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()" id="navbar">
    <div class="container">
        <!-- LOGO -->
        <a class="navbar-brand logo" [routerLink]="['/home']">
            <img src="assets/images/keeel-logo.png" alt="" class="" height="60">
        </a>
        <button class="navbar-toggler p-0" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
            <i class="mdi mdi-menu"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav ml-auto navbar-center" id="mySidenav">
                <li *ngIf="userDetails!=null" class="nav-item">
                    <a class="nav-link">Welcome,{{ userDetails['name']}}</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}" (click)="gotoNext()">
                    <a [ngxScrollTo]="'#home'" [routerLink]="['/home']" class="nav-link" (click)="gotoNext()">Home</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}" (click)="gotoNext()">
                    <a [ngxScrollTo]="'#features'" [routerLink]="['/home']" fragment="features" class="nav-link" [ngxScrollToOffset]="2" >Features</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'about'}" >
                    <a [ngxScrollTo]="'#about'" [routerLink]="['/home']" fragment="about" class="nav-link" [ngxScrollToOffset]="2" >About Us</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'download'}" >
                    <a [ngxScrollTo]="'#download'" [routerLink]="['/home']" fragment="download" class="nav-link" [ngxScrollToOffset]="2" >Download App</a>
                </li>
                <!-- <li class="nav-item" [ngClass]="{'active':currentSection === 'free-material'}" (click)="gotoNext()">
                    <a [routerLink]="['/materials']" class="nav-link" (click)="gotoNext()">Free
                        Material</a>
                </li>
                <li *ngIf="userDetails!=null" [ngClass]="{'active':currentSection === 'pricing'}" (click)="gotoNext()">
                    <a [routerLink]="['/pricing']" class="nav-link" (click)="gotoNext()">Pricing</a>
                </li>
                <li [ngClass]="{'active':currentSection === 'offer'}" (click)="gotoNext()">
                    <a [routerLink]="['/offer']" class="nav-link" (click)="gotoNext()">Special Offer</a>
                </li>
                <li>
                    <a href="https://blog.keeel.in/" target="_blank" class="nav-link">Blog</a>
                </li>
                <li style="cursor: pointer;" class="nav-item" [ngClass]="{'active':currentSection === 'features'}" (click)="open('focusFirst')">
                    <a class="nav-link">{{userDetails==null?"Login":"Logout"}}</a>

                </li> -->
            </ul>
            <!-- <div class="call-no">
                <a href="#" class="nav-link text-warning"><i class="mdi mdi-phone mr-1"></i> +00 1234 5678 90</a>
            </div> -->
        </div>
    </div>
</nav>
<!-- Navbar End -->

<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
    <section class="hero-1-bg position-relative bg-light d-flex align-items-center" id="home" *ngIf="!showFreeMatrial">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="hero-title pr-lg-5">
                        <!-- <p class="text-uppercase text-primary font-weight-medium f-14 mb-4">Excellence learning
                        </p> -->
                        <h1 class="hero-1-title mb-4 font-weight-normal line-height-1_4">Welcome To Kido Learn
                        </h1>
                        <!-- <h1 class="hero-1-title mb-4 font-weight-normal line-height-1_4">Feel the Learning Zeal
                        </h1> -->
                        <p class="text-muted mb-4 pb-2">Learning,Cant Be Done In Boundaries ,They Are Meant To Break Limits,& Helpful To Make Better Life & Future.</p>
                        <a [ngxScrollTo]="'#features'" class="btn btn-warning">Our App Features <span
                                class="ml-2 right-icon">&#8594;</span></a>
                        <br>


                        <!-- <div class="flex social-btns">

                            <a class="app-btn blu flex vert" href="https://play.google.com/store/apps/details?id=com.keeeleducation.app">
                                <i class="fab fa-google-play"></i>
                                <p>Get it on <br /> <span class="big-txt">Google Play</span></p>
                            </a>
                            <a class="app-btn blu flex vert" href="http:apple.com">
                                <i class="fab fa-apple"></i>
                                <p>Available on the <br /> <span class="big-txt">App Store</span></p>
                            </a>

                        </div> -->

                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="mt-5 mt-lg-0">
                        <img src="assets/images/hero-1-img.png" alt="" class="img-fluid mx-auto d-block">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- <div *ngIf="!showFreeMatrial"> -->
    <!-- <app-services></app-services> -->
    <app-features></app-features>
    <app-about></app-about>
    <app-download-app></app-download-app>
    <!-- <app-contact></app-contact> -->

    <!-- </div> -->
    <!-- <div *ngIf="showFreeMatrial">
        <app-free-materials (isloading)="setLoading($event)"></app-free-materials>
    </div> -->
    <app-footer></app-footer>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<app-scroll-to-top></app-scroll-to-top>