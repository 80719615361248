<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()" id="navbar">
    <div class="container">
        <!-- LOGO -->
        <a class="navbar-brand logo" [routerLink]="['/home']">
            <img src="assets/images/keeel-logo.png" alt="" class="" height="60">
        </a>
        <button class="navbar-toggler p-0" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
            <i class="mdi mdi-menu"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav ml-auto navbar-center" id="mySidenav">
                <li *ngIf="userDetails!=null" class="nav-item">
                    <a class="nav-link">Welcome,{{ userDetails['name']}}</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}" (click)="gotoNext()">
                    <a [ngxScrollTo]="'#home'" [routerLink]="['/home']" class="nav-link" (click)="gotoNext()">Home</a>
                </li>
                <!-- <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}" (click)="gotoNext()">
                    <a [ngxScrollTo]="'#features'" [routerLink]="['/home']" fragment="features" class="nav-link" [ngxScrollToOffset]="2" (click)="gotoNext()">Features</a>
                </li> -->
                <li class="nav-item" [ngClass]="{'active':currentSection === 'free-material'}" (click)="gotoNext()">
                    <a [routerLink]="['/materials']" class="nav-link" (click)="gotoNext()">Free
                        Material</a>
                </li>
                <li *ngIf="userDetails!=null" [ngClass]="{'active':currentSection === 'pricing'}" (click)="gotoNext()">
                    <a [routerLink]="['/pricing']" class="nav-link" (click)="gotoNext()">Pricing</a>
                </li>
                <li [ngClass]="{'active':currentSection === 'offer'}" (click)="gotoNext()">
                    <a [routerLink]="['/offer']" class="nav-link" (click)="gotoNext()">Special Offer</a>
                </li>
                <li>
                    <a href="https://blog.keeel.in/" target="_blank" class="nav-link">Blog</a>
                </li>
                <li style="cursor: pointer;" class="nav-item" [ngClass]="{'active':currentSection === 'features'}" (click)="open('focusFirst')">
                    <a class="nav-link">{{userDetails==null?"Login":"Logout"}}</a>
                </li>

            </ul>

        </div>
    </div>
</nav>
<!-- Navbar End -->
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
    <app-price-page></app-price-page>
    <app-footer></app-footer>
</div>
<app-scroll-to-top></app-scroll-to-top>