import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-price-page',
  templateUrl: './price-page.component.html',
  styleUrls: ['./price-page.component.scss'],
})
export class PricePageComponent implements OnInit {
  windowRef: any;
  selectedPlan: any;
  planDetail: any = [];
  benefits = [
    'Books & Papers',
    'Videos',
    'Live Lectures',
    'Quiz Assessment(MCQs)',
    'MCQ Analysis',
    'Doubt Hub',
  ];
  priceList: any = [
    { type: '3M', final: '1290', mrp: '15000' },
    { type: '12M', final: '3990', mrp: '15000' },
    { type: '1M', final: '490', mrp: '10000' },
    { type: '6M', final: '2390', mrp: '15000' },
  ];

  userDetails: any;
  isLoading: boolean = false;
  razorpay_payment_id: string;
  gotAnySubscription: boolean = false;
  constructor(
    public as: AppService,
    private storage: AngularFireStorage,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private firestore: AngularFirestore,
    public routes: ActivatedRoute,
    private router: Router
  ) {
    this.windowRef = this.as.windowRef;
    this.userDetails = JSON.parse(localStorage.getItem('keeelWebUser'));
  }
  ngOnInit(): void {
    if (this.userDetails == null) {
      this.router.navigate(['/home']);
    } else {
      this.getRazorPayId();
    }
  }
  getRazorPayId() {
    this.isLoading = true;
    this.spinner.show();
    var collectionName = 'RAZORPAY';
    const data = this.firestore.collection(collectionName);
    data.get().subscribe(async (val) => {
      if (val.docs.length > 0) {
        this.razorpay_payment_id = val.docs[0].data()['razorpayId'];
        this.getPlanFromDB();
      } else {
        this.getPlanFromDB();
      }
    });
  }
  getPlanFromDB() {
    // this.isLoading = true;
    // this.spinner.show();
    var collectionName = 'Pricing';
    const data = this.firestore.collection(collectionName);
    data.get().subscribe(async (val) => {
      if (val.docs.length > 0) {
        val.docs.forEach((element) => {
          this.planDetail.push(element.data());
        });
        console.log(this.planDetail);
        this.getPlanOfBoard(
          this.userDetails['board'],
          this.userDetails['class']
        );
        this.isLoading = false;
        this.spinner.hide();
      } else {
        this.isLoading = false;
        this.spinner.hide();
      }
    });
  }

  getPlanOfBoard(board: any, standard: any) {
    let boardDetail;
    let arrayDetail = [];

    this.planDetail.forEach((planDetail) => {
      for (const key in planDetail) {
        if (board == key) {
          boardDetail = planDetail[key];
        }
      }
    });
    for (const plan in boardDetail) {
      if (standard == plan) {
        arrayDetail = [];
        for (const detail in boardDetail[plan]['platinum']) {
          arrayDetail.push({
            index:
              detail == '1M' ? 1 : detail == '3M' ? 2 : detail == '6M' ? 3 : 4,
            type: detail,
            final: boardDetail[plan]['platinum'][detail]['final'],
            mrp: boardDetail[plan]['platinum'][detail]['mrp'],
          });
        }
      }
    }
    console.log(arrayDetail);
    arrayDetail.sort((a, b) => {
      return a.index - b.index;
    });
    console.log(arrayDetail);

    this.selectedPlan = arrayDetail;
  }
  isPurchased(board: any, standard: any, period: any) {
    let isValid = false;
    let boardDetail;

    this.planDetail.forEach((planDetail) => {
      for (const key in planDetail) {
        if (board == key) {
          boardDetail = planDetail[key];
        }
      }
    });
    for (const plan in boardDetail) {
      if (standard == plan) {
        for (const duration in boardDetail[plan]['platinum']) {
          if (duration == period) {
            if (this.userDetails['duration'] == duration + 'PLATINUM') {
              isValid = true;
            }
          }
        }
      }
    }
    return isValid;
  }

  subscribe(price, duration) {
    let options = {
      key: this.razorpay_payment_id, // Enter the Key ID generated from the Dashboard
      // "amount": 1 * 100,
      amount: price * 100,
      currency: 'INR',
      name: 'KEEEL',
      description: 'Plan ' + price + ' (inclusive GST)',
      prefill: {
        name: this.userDetails['name'],
        email: this.userDetails['email'],
        contact: this.userDetails['mobileNo'],
      },
      handler: (res) => {
        console.log(res);
        this.updateUserIntoTable(res, 'PLATINUM', price, duration);
      },
    };
    var rzp1 = new this.windowRef.Razorpay(options);
    rzp1.open();
    rzp1.on('payment.failed', this._handlePaymentError);
    console.log('works');
  }
  _handlePaymentError(res) {
    console.log(res);
  }
  async updateUserIntoTable(res, masterPlan, price, duration) {
    console.log(res);
    let endDate = 0;
    if (duration == '3M') {
      endDate = 90;
    } else if (duration == '6M') {
      endDate = 180;
    } else if (duration == '12M') {
      endDate = 365;
    }
    let listOfTransactions = [];
    let currentTime = new Date();
    let planValidTill = new Date();
    this.getTimePlusXDays(planValidTill, endDate);
    var collectionName = 'users';
    this.isLoading = true;
    this.spinner.show();
    try {
      const data = this.firestore
        .collection(collectionName)
        .doc(this.userDetails['userId']).ref;
      await data.get().then(async (docs) => {
        if (docs.exists) {
          console.log(docs.data());
          listOfTransactions =
            docs.data()['listOfTransactionId'] == undefined
              ? []
              : docs.data()['listOfTransactionId'];
          listOfTransactions.push({
            type: 'Success',
            transactionId: res.razorpay_payment_id,
            plan: masterPlan,
            duration: duration + masterPlan,
            amount: price,
            planValidTill: planValidTill.toISOString(),
            planStartingDate: currentTime,
          });
          await this.firestore
            .collection(collectionName)
            .doc(this.userDetails['userId'])
            .update({
              plan: masterPlan,
              planValidTill: planValidTill.toISOString(),
              listOfTransactionId: listOfTransactions,
              duration: duration + masterPlan,
            });
          localStorage.removeItem('keeelWebUser');
          localStorage.setItem(
            'keeelWebUser',
            JSON.stringify({
              userId: this.userDetails['userId'],
              name: this.userDetails['name'],
              email: this.userDetails['email'],
              board: this.userDetails['board'],
              duration: duration + masterPlan,
              plan: masterPlan,
              class: this.userDetails['class'],
            })
          );
          this.userDetails['plan'] = masterPlan;
          this.userDetails['duration'] = duration + masterPlan;

          this.priceList.forEach((plan) => {
            plan['plans'].forEach((subPlans) => {
              if (
                this.userDetails['duration'] ==
                subPlans['details']['duration'] +
                  (plan['type'] == 'platinum' ? 'PLATINUM' : 'GOLD')
              ) {
                subPlans['details']['isCurrentPlan'] = true;
                this.gotAnySubscription = true;
              }
            });
          });
          this.isLoading = false;
          this.spinner.hide();
          location.reload();
          this.showSuccessToaster('Subscription done successfully', 'Success');
        } else {
          console.log('There was an error getting your document:');
        }
      });
    } catch (error) {
      this.isLoading = false;
      this.spinner.hide();
      this.showErrorToaster(
        'Some error occured while subscribing, if money is deducted kindly contact our support team',
        'Error'
      );
    }
  }
  getTimePlusXDays(dateTime: Date, x: number) {
    return dateTime.setDate(dateTime.getDate() + x);
  }
  showSuccessToaster(message, title) {
    this.toastr.error(message, title);
  }
  showErrorToaster(message, title) {
    this.toastr.error(message, title);
  }
  showWarningToaster(message, title) {
    this.toastr.warning(message, title);
  }
}
