<div class="row top-row" id="download">
    <div class="col-md-6">
        <div>
            <div class="h2-section">
                Get mobile app easily
            </div>
            <div class="inner-section">
                Download the app for best online learning experience
            </div>
        </div>
        <div class="row">
            <div class="align-cnter col-md-12">
                <a href="https://play.google.com/store/apps/details?id=com.keeeleducation.app"><img class="download-img" src="../../../assets/images/features/playstore.png" alt="" srcset="">
                </a>
                <a href="https://apps.apple.com/us/app/keeel-feel-the-learning-zeal/id1566539952">
                    <img class="download-img" src="../../../assets/images/features/appstore.png" alt="" srcset="">
                </a>
            </div>
        </div>
    </div>
    <div class="mobile-img col-md-6">
        <div class="row">
            <div class="col-md-12">
                <img style="height: 20em;margin-right: 1em;" src="../../../assets/images/features/2_iphoneseblack_portrait.png" alt="" srcset="">
                <img style="height: 20em;margin-right: 1em;" src="../../../assets/images/features/3_iphonesewhite_portrait.png" alt="" srcset="">
                <img style="height: 20em;" src="../../../assets/images/features/4_iphoneseblack_portrait.png" alt="" srcset="">

            </div>

        </div>
    </div>

</div>