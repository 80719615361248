import { Component, OnInit, Type } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialAuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-view-pdf-popup',
  templateUrl: './view-pdf-popup.component.html',
  styleUrls: ['./view-pdf-popup.component.css'],
})
export class ViewPdfPopupComponent implements OnInit {
  userDetails: any;
  constructor(
    public modal: NgbActiveModal,
    private authService: SocialAuthService,
    private _modalService: NgbModal,
    public as: AppService,
    private formBuilder: FormBuilder,
    private firestore: AngularFirestore,
    private toastr: ToastrService
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('keeelWebUser'));
  }
  ngOnInit(): void {
    
  }
}
