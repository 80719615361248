<div class="modal-header">
    <h4 class="modal-title" id="modal-title" style="font-size: large;">KEEEL COMBO OFFER REGISTRATION FORM </h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <!-- <div class="row">
        <div class="col-md-12" style="text-decoration: underline;color: blue;text-align: end;font-size: 12px;">
            <span (click)="openLink()" style="cursor: pointer;">
                Click Here for More Details
            </span>
        </div>
    </div> -->
    <form [formGroup]="addDeatilForComboOfferForm" name="addDeatilForComboOfferForm">
        <div class="row">
            <div class="col-md-12">
                <label for="name" class="label required label-bold">Name</label>
                <input type="text" placeholder="Name" id="name" formControlName="name" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="email" class="label required label-bold">Email</label>
                <input type="text" placeholder="Email" id="email" formControlName="email" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="mobileNo" class="label required label-bold">Mobile Number</label>
                <input type="text" placeholder="Mobile Number" id="mobileNo" formControlName="mobileNo" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="address" class="label required label-bold">Address</label>
                <input type="text" placeholder="Address" id="address" formControlName="address" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="responsibility" class="label required label-bold">TAB, IPADS & MOBILES ARE WITH BRAND
                    WARRANTY, KEEEL APP IS NOT RESPONSIBLE FOR THEM</label><br>
                <div class="row">
                    <div class="col-md-6">
                        <input type="radio" id="agree" name="responsibility" value="Agree" formControlName="responsibility">   <label for="agree">Agree </label><br>
                    </div>
                    <div class="col-md-6">
                        <input type="radio" id="disagree" name="responsibility" value="Disagree" formControlName="responsibility">   <label for="disagree">Disagree </label><br>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="refund" class="label required label-bold">Phone , Ipads or Tab Price + 10,000 Rupees
                    will not be Refunded, in case of any refund requested</label><br>
                <div class="row">
                    <div class="col-md-6">
                        <input type="radio" id="agree" name="refund" value="Agree" formControlName="refund">   <label for="agree">Agree </label><br>
                    </div>
                    <div class="col-md-6">
                        <input type="radio" id="disagree" name="refund" value="Disagree" formControlName="refund">
                        <label for="disagree">Disagree </label><br>
                    </div>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <div class="save">
                    <input type="submit" [ngClass]="{'save-btn-disabled':addDeatilForComboOfferForm.invalid ,'save-btn':addDeatilForComboOfferForm.valid }" value="{{'Save'}}" [disabled]="addDeatilForComboOfferForm.invalid " (click)="registerUserIfNotExist()" />

                </div>
            </div>
        </div>
    </form>

</div>