<div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{'Get Access to our site using'}}</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div *ngIf="!showStandardAsk">
        <div class="row login-row" (click)="signInWithGoogle()">
            <img class="login-icon" src="../../../assets/images/gmail.webp" alt="" srcset=""> Sign In With Gmail
        </div>

    </div>

    <form *ngIf="showStandardAsk" [formGroup]="addExtraDetailForm" name="addExtraDetailForm">
        <!-- <div class="login-container"> -->
        <div class="row">
            <div class="col-md-12">
                <label for="mobileNo" class="label required">Mobile Number</label>
                <input type="text" placeholder="Mobile Number" id="mobileNo" formControlName="mobileNo" class="input-style">

            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="boardName" class="label required">Select Board</label>
                <select #mySelect id="boardName" name="boardName" class="input-style" formControlName="boardName" (change)="onBoardSelectionChange(mySelect.value)">
                    <option value="Select Board" selected disabled>Select Board</option>
                    <option value="ICSE">ICSE/ISC</option>
                    <option value="SSC">MAHARASHTRA BOARD</option>
                    <option value="CBSE">CBSE</option>
                    <option value="OTHERBOARD">OTHER BOARD</option>
                </select>
            </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="className" class="label required">Select Class</label>
                <select id="className" name="className" class="input-style" formControlName="className">
                    <option value="Select Class" selected disabled>Select Class</option>
                    <option *ngFor="let class of listOfClass" [value]="class['label']">{{class['label']}}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="save">
                    <input type="submit" [ngClass]="{'save-btn-disabled':addExtraDetailForm.invalid ,'save-btn':addExtraDetailForm.valid }" value="{{'Save'}}" [disabled]="addExtraDetailForm.invalid " (click)="registerUserIfNotExist()" />

                </div>
            </div>
        </div>
        <!-- </div> -->
    </form>

</div>