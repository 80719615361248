import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ViewPdfComponent } from './shared/view-pdf/view-pdf.component';

const routes: Routes = [
  { path: 'view-pdf', component: ViewPdfComponent },
  { path: '', loadChildren: () => import('./layouts/layouts.module').then(m => m.LayoutsModule) },
  {
    path: '**',
    redirectTo: '/home', pathMatch: 'full',
  },
  {
    path: '',
    redirectTo: '/home', pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, 
  //   {
  //   useHash: true
  // }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
