import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CountToModule } from 'angular-count-to';
import { LayoutsRoutingModule } from './layout-routing.module';
import { Layout1Component } from './layout1/layout1.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MaterialsComponent } from './materials/materials.component';
import { LoginSignupDialogComponent } from './login-signup-dialog/login-signup-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { PricingComponent } from './pricing/pricing.component';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';
import { AppleSigninModule } from 'ngx-apple-signin';
import { SpecialOffersComponent } from './special-offers/special-offers.component';
@NgModule({
  declarations: [Layout1Component, MaterialsComponent, LoginSignupDialogComponent, PricingComponent, ScrollToTopComponent, SpecialOffersComponent,],
  imports: [
    CommonModule,
    LayoutsRoutingModule,
    SharedModule,
    ScrollToModule.forRoot(),
    CarouselModule,
    CountToModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    AppleSigninModule
  ],
  exports: [ScrollToTopComponent]

})
export class LayoutsModule { }
// VVZT42QPK2.com.keeel.keeeleducation
