<!-- About us Start -->
<section class="section" id="about">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mb-5">
                    <h3 class="font-weight-normal text-dark">About <span class="text-warning">Us</span></h3>
                    <p class="text-muted">Kido Learn Is A Digital Platform, Which Is Devoted for Helping Reach Quality Education To All .We Are Mumbai Based Company.</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4 mg-1">
                <h2 class="passionate font-weight-light line-height-1_6 text-dark mb-4">Passionate about providing best teaching and quality.</h2>
            </div>
            <div class="col-md-7 offset-md-1">
                <div class="row">
                    <div class="col-md-6 mg-1">
                        <h6 class="mission text-dark font-weight-light f-20 mb-3">Our Mission</h6>
                        <p class="mission-text text-muted font-weight-light">To provide 100% ad free learning platform for student with quality content and with best educational support with the element of technology to reach the remotest area of the world and to fulfil the mission of quality education
                            for all the students across the globe.</p>
                    </div>
                    <div class="col-md-6">
                        <h6 class="mission text-dark font-weight-light f-20 mb-3">Our Vision</h6>
                        <p class="mission-text text-muted font-weight-light">In the pandemic time, Best education is difficult to achieve, so that by technology support we are going to provide the best educational services and support to all students, By which each and every student get taught well and
                            raise their wing to achieve big opportunities.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- About us End -->