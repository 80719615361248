<!-- Features Start -->
<section class="section" id="features">
    <div *ngIf="width >= 992" class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mb-5">
                    <h3 class="font-weight-normal text-dark">Our <span class="text-warning">Features</span></h3>
                    <!-- <p class="text-muted">10 KA DUM FEATURES</p> -->
                </div>
            </div>
        </div>

        <div class="row align-items-center">
            <div class="col-md-5" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/freebooks.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
            <div class="col-md-6 offset-md-1">
                <div class="features-box mt-5 mt-sm-0 mb-4" style="cursor: pointer;" (click)="gotoMaterials()">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-book"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">FREE BOOKS<sub class="sub-text">Free</sub></h5>
                    <p class="text-muted mb-3 f-15">NCERT Solutions, RD Sharma Solution, HC Verma Solution, Maharashtra State Boards Solution, ML Aggarwal Solutions,Selina Solutions,Frank Solutions, Evergreen solutions, Viraf Dalal Solutions, Sushma Agrawal Solutions, RS Agrawal Solutions
                        and So Many other books. .
                    </p>
                </div>
            </div>
        </div>

        <!-- <div class="row align-items-center mt-5">
            <div class="col-md-6">
                <div class="features-box mb-4" style="cursor: pointer;" (click)="gotoMaterials()">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-newspaper"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">FREE TEST PAPERS<sub class="sub-text">Free</sub>
                    </h5>
                    <p class="text-muted mb-3 f-15">Practise to Perfection with Chapter wise, Subject wise ,Quarterly, Semester wise, weekly Test Papers.</p>
                </div>
            </div>
            <div class="col-md-5 offset-md-1 mt-5 mt-sm-0" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/img-2.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5">
            <div class="col-md-5" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/img-1.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
            <div class="col-md-6 offset-md-1">
                <div class="features-box mt-5 mt-sm-0 mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-file-video"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">VIDEOS <sub class="sub-text">App only</sub></h5>
                    <p class="text-muted mb-3 f-15">Prepare for Exams and Beyond with videos,Taught by some of India’s Best Teachers, Mostly the Language will be Hinglish (Hindi + English), and English .
                    </p>
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5">
            <div class="col-md-6">
                <div class="features-box mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-account-group"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">LIVE LECTURES<sub class="sub-text">App only</sub>
                    </h5>
                    <p class="text-muted mb-3 f-15">Best Faculty Team will be live for you, so you remain Pre-prepared with your Exams. Proper Live Lecture Schedule will be given on App.</p>
                </div>
            </div>
            <div class="col-md-5 offset-md-1 mt-5 mt-sm-0" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/livelecture.jpg" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5">
            <div class="col-md-5" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/doubthub.jpg" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
            <div class="col-md-6 offset-md-1">
                <div class="features-box mt-5 mt-sm-0 mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-account-question"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">DOUBT HUB<sub class="sub-text">App only</sub>
                    </h5>
                    <p class="text-muted mb-3 f-15">Get Doubts Cleared instantly by our Teachers in our Dedicated Telegram Channel. (Download Telegram App And Register Yourself Before using it.)</p>
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5">
            <div class="col-md-6">
                <div class="features-box mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-cloud-question"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">MCQ’s<sub class="sub-text">App only</sub></h5>
                    <p class="text-muted mb-3 f-15">Practice with Unlimited Multiple Choice Questions, Separate Papers for IITJEE,NEET and CET for MOCK Practice Or Chapter wise Practice ( For 11th – 12th ). MCQs will be also Available for 6th to 10th Standard Students.</p>
                </div>
            </div>
            <div class="col-md-5 offset-md-1 mt-5 mt-sm-0" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/mcqs.jpg" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5">
            <div class="col-md-5" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/testanalysis.jpg" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
            <div class="col-md-6 offset-md-1">
                <div class="features-box mt-5 mt-sm-0 mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-chart-bell-curve"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">TEST ANALYSIS <sub class="sub-text">App
                            only</sub></h5>
                    <p class="text-muted mb-3 f-15">Based on your MCQs Test, a Detailed Test Analysis will be Given to you ,so that Students Should identify there weak Points and improve there Studies.</p>
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5">
            <div class="col-md-6">
                <div class="features-box mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-notification-clear-all"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">EXAM BLOGS<sub class="sub-text">App only</sub>
                    </h5>
                    <p class="text-muted mb-3 f-15">You will Never Miss any Exams, Their Important Dates. </p>
                </div>
            </div>
            <div class="col-md-5 offset-md-1 mt-5 mt-sm-0" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/examvlogs.jpg" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5">
            <div class="col-md-5" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/img-3.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
            <div class="col-md-6 offset-md-1">
                <div class="features-box mt-5 mt-sm-0 mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-compare"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">COMPETITION CORNER<sub class="sub-text">App
                            only</sub></h5>
                    <p class="text-muted mb-3 f-15">We Got all the Best Competition Materials Books & Papers for you to Prepare for Exams Like IITJEE,NEET,CET,OLYMPIADS,NTSE,KVPY etc.</p>
                </div>
            </div>
        </div> -->

        <div class="row align-items-center mt-5">
            <div class="col-md-6">
                <div class="features-box mb-4">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-library-video"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">E-LEARNING<sub class="sub-text">App only</sub>
                    </h5>
                    <p class="text-muted mb-3 f-15">Revise with Animated Videos in Hindi and English to Help you Understand the Topics Well.</p>
                </div>
            </div>
            <div class="col-md-5 offset-md-1 mt-5 mt-sm-0" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img src="assets/images/features/elearning.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="width < 992" class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mb-5">
                    <h3 class="font-weight-normal text-dark">Our <span class="text-warning">Features</span></h3>
                    <!-- <p class="text-muted">10 KA DUM FEATURES</p> -->
                </div>
            </div>
        </div>

        <div class="row align-items-center">

            <div class="col-md-6 offset-md-1">
                <div class="features-box mt-5 mt-sm-0 mb-4" style="cursor: pointer;text-align: center;" (click)="gotoMaterials()">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-book"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">FREE BOOKS<sub class="sub-text">Free</sub></h5>
                    <p class="text-muted mb-3 f-15">NCERT Solutions, RD Sharma Solution, HC Verma Solution, Maharashtra State Boards Solution, ML Aggarwal Solutions,Selina Solutions,Frank Solutions, Evergreen solutions, Viraf Dalal Solutions, Sushma Agrawal Solutions, RS Agrawal Solutions
                        and So Many other books. .
                    </p>
                </div>
            </div>
            <div class="col-md-5" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img style="height: 15em;" src="assets/images/features/freebooks.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
        </div>

        <!-- <div class="row align-items-center mt-5">
            <div class="col-md-6">
                <div class="features-box mb-4" style="cursor: pointer;text-align: center;" (click)="gotoMaterials()">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-newspaper"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">FREE TEST PAPERS<sub class="sub-text">Free</sub>
                    </h5>
                    <p class="text-muted mb-3 f-15">Practise to Perfection with Chapter wise, Subject wise ,Quarterly, Semester wise, weekly Test Papers.</p>
                </div>
            </div>
            <div class="col-md-5 offset-md-1 mt-5 mt-sm-0" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img style="height: 15em;" src="assets/images/features/img-2.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5">

            <div class="col-md-6 offset-md-1">
                <div class="features-box mt-5 mt-sm-0 mb-4" style="text-align: center;">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-file-video"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">VIDEOS <sub class="sub-text">App only</sub></h5>
                    <p class="text-muted mb-3 f-15">Prepare for Exams and Beyond with videos,Taught by some of India’s Best Teachers, Mostly the Language will be Hinglish (Hindi + English), and English .
                    </p>
                </div>
            </div>
            <div class="col-md-5" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img style="height: 15em;" src="assets/images/features/img-1.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5">
            <div class="col-md-6">
                <div class="features-box mb-4" style="text-align: center;">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-account-group"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">LIVE LECTURES<sub class="sub-text">App only</sub>
                    </h5>
                    <p class="text-muted mb-3 f-15">Best Faculty Team will be live for you, so you remain Pre-prepared with your Exams. Proper Live Lecture Schedule will be given on App.</p>
                </div>
            </div>
            <div class="col-md-5 offset-md-1 mt-5 mt-sm-0" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img style="height: 15em;" src="assets/images/features/livelecture.jpg" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5">

            <div class="col-md-6 offset-md-1">
                <div class="features-box mt-5 mt-sm-0 mb-4" style="text-align: center;">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-account-question"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">DOUBT HUB<sub class="sub-text">App only</sub>
                    </h5>
                    <p class="text-muted mb-3 f-15">Get Doubts Cleared instantly by our Teachers in our Dedicated Telegram Channel. (Download Telegram App And Register Yourself Before using it.)</p>
                </div>
            </div>
            <div class="col-md-5" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img style="height: 15em;" src="assets/images/features/doubthub.jpg" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5">
            <div class="col-md-6">
                <div class="features-box mb-4" style="text-align: center;">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-cloud-question"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">MCQ’s<sub class="sub-text">App only</sub></h5>
                    <p class="text-muted mb-3 f-15">Practice with Unlimited Multiple Choice Questions, Separate Papers for IITJEE,NEET and CET for MOCK Practice Or Chapter wise Practice ( For 11th – 12th ). MCQs will be also Available for 6th to 10th Standard Students.</p>
                </div>
            </div>
            <div class="col-md-5 offset-md-1 mt-5 mt-sm-0" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img style="height: 15em;" src="assets/images/features/mcqs.jpg" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5">

            <div class="col-md-6 offset-md-1">
                <div class="features-box mt-5 mt-sm-0 mb-4" style="text-align: center;">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-chart-bell-curve"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">TEST ANALYSIS <sub class="sub-text">App
                            only</sub></h5>
                    <p class="text-muted mb-3 f-15">Based on your MCQs Test, a Detailed Test Analysis will be Given to you ,so that Students Should identify there weak Points and improve there Studies.</p>
                </div>
            </div>
            <div class="col-md-5" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img style="height: 15em;" src="assets/images/features/testanalysis.jpg" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5">
            <div class="col-md-6">
                <div class="features-box mb-4" style="text-align: center;">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-notification-clear-all"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">EXAM BLOGS<sub class="sub-text">App only</sub>
                    </h5>
                    <p class="text-muted mb-3 f-15">You will Never Miss any Exams, Their Important Dates. </p>
                </div>
            </div>
            <div class="col-md-5 offset-md-1 mt-5 mt-sm-0" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img style="height: 15em;" src="assets/images/features/examvlogs.jpg" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5">

            <div class="col-md-6 offset-md-1">
                <div class="features-box mt-5 mt-sm-0 mb-4" style="text-align: center;">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-compare"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">COMPETITION CORNER<sub class="sub-text">App
                            only</sub></h5>
                    <p class="text-muted mb-3 f-15">We Got all the Best Competition Materials Books & Papers for you to Prepare for Exams Like IITJEE,NEET,CET,OLYMPIADS,NTSE,KVPY etc.</p>
                </div>
            </div>
            <div class="col-md-5" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img style="height: 15em;" src="assets/images/features/img-3.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
        </div> -->

        <div class="row align-items-center mt-5">
            <div class="col-md-6">
                <div class="features-box mb-4" style="text-align: center;">
                    <div class="features-icon my-4">
                        <i class="mdi mdi-library-video"></i>
                    </div>
                    <h5 class="text-dark font-weight-normal mb-3 pt-3">E-LEARNING<sub class="sub-text">App only</sub>
                    </h5>
                    <p class="text-muted mb-3 f-15">Revise with Animated Videos in Hindi and English to Help you Understand the Topics Well.</p>
                </div>
            </div>
            <div class="col-md-5 offset-md-1 mt-5 mt-sm-0" style="background: url(assets/images/features/features-bg.png) center center">
                <div class="features-img">
                    <img style="height: 15em;" src="assets/images/features/elearning.png" alt="" class="img-fluid d-block mx-auto">
                </div>
            </div>
        </div>
    </div>
</section>