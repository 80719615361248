import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  basePath: any = '/v1/KEEEL/';
  // private basePath = '/v1/KEEEL/X/SSC/TEXTBOOKS';
  // private basePath = '/v1/KEEEL/X/SSC/TEXTBOOKS/Algebra Solutions';

  constructor(
    private db: AngularFireDatabase,
    private storage: AngularFireStorage
  ) {}
  async getFolders(boardName: any, className: any, feature: any, topic: any) {
    var listOfFolders: any = [];
    let newPath = '';
    if (topic != undefined) {
      newPath = this.basePath + `${className}/${boardName}/${feature}/${topic}`;
    } else {
      newPath = this.basePath + `${className}/${boardName}/${feature}`;
    }
    const fileRef = this.storage.ref(newPath);
    var wait = new Promise((resolve, reject) => {
      fileRef.listAll().subscribe(function (result) {
        result.prefixes.forEach((el) => {
          listOfFolders.push({ subjectName: el.name });
          // console.log(el.name);
        });
        resolve(listOfFolders);
      });
    });
    let result = await wait;
    return result;
  }
  async getFiles(
    boardName: any,
    className: any,
    feature: any,
    topic: any,
    innerTopic: any
  ) {
    var listOfFiles: any = [];
    let newPath = '';
    if (innerTopic != undefined) {
      newPath =
        this.basePath +
        `${className}/${boardName}/${feature}/${topic}/${innerTopic}`;
    } else {
      newPath = this.basePath + `${className}/${boardName}/${feature}/${topic}`;
    }
    const fileRef = this.storage.ref(newPath);
    var wait = new Promise(async (resolve, reject) => {
      await fileRef.listAll().subscribe(async function (result) {
        if (result.items.length > 0) {
          await result.items.forEach((el) => {
            new Promise(async (res, rej) => {
              await el.getDownloadURL().then((vl) => {
                res(vl);
              });
            }).then((val) => {
              listOfFiles.push({
                topicName: String(el.name).replace(/_/g, ' '),
                topicUrl: val,
              });
              if (result.items.length == listOfFiles.length) {
                resolve(listOfFiles);
              }
            });
          });
        } else {
          resolve(listOfFiles);
        }
      });
    });
    let result = await wait;
    return result;
  }
}
