import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';


@Injectable({
    providedIn: 'root'
})
export class AppService {
    selectedFeature: any;
    selectedBoard: any;
    selectedClass: any;
    selectedSubjectOrTopic: any;
    // subjectOrTopicNameList: any;
    calledFrom: any;

    pdfLinkToShow:any;

    boardList: any = [
        { "id": '1', "image": "assets/boards/icse.jpg", "name": "ICSE/ISC" },
        { "id": '2', "image": "assets/boards/cbse.jpg", "name": "SSC/HSC" },
        { "id": '3', "image": "assets/boards/ssc.png", "name": "CBSE" },
        { "id": '4', "image": "assets/boards/ssc.png", "name": "OTHER BOARD" },
    ];
    classNameList: any = [];
    subjectOrTopicNameList: any = [];
    listOfFiles: any = [];
    constructor(private db: AngularFireDatabase, private storage: AngularFireStorage) { }
    get windowRef() {
        return window;
    }

    // gapi: any;
    // public auth2: any;
    // public googleInit() {
    //     this.gapi.load('auth2', () => {
    //         this.auth2 = this.gapi.auth2.init({
    //             client_id: 'YOUR_CLIENT_ID.apps.googleusercontent.com',
    //             cookiepolicy: 'single_host_origin',
    //             scope: 'profile email'
    //         });
    //         this.attachSignin(document.getElementById('googleBtn'));
    //     });
    // }
    // public attachSignin(element) {
    //     this.auth2.attachClickHandler(element, {},
    //         (googleUser) => {

    //             let profile = googleUser.getBasicProfile();
    //             console.log('Token || ' + googleUser.getAuthResponse().id_token);
    //             console.log('ID: ' + profile.getId());
    //             console.log('Name: ' + profile.getName());
    //             console.log('Image URL: ' + profile.getImageUrl());
    //             console.log('Email: ' + profile.getEmail());
    //             //YOUR CODE HERE


    //         }, (error) => {
    //             alert(JSON.stringify(error, undefined, 2));
    //         });
    // }
}


