<div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{'Alert'}}</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div>
        <div class="row login-row">
            For non stop content view, kindly download our app
        </div>
        <div class="row">
            <div class="align-cnter col-md-12">
                <a href="https://play.google.com/store/apps/details?id=com.keeeleducation.app"><img class="download-img" src="../../../assets/images/features/playstore.png" alt="" srcset="">
                </a>
                <a href="https://apps.apple.com/us/app/keeel-feel-the-learning-zeal/id1566539952">
                    <img class="download-img" src="../../../assets/images/features/appstore.png" alt="" srcset="">
                </a>
            </div>
        </div>
    </div>


</div>