<div style="margin-top: 5em;margin-left: 5em;margin-right: 5em;padding-bottom: 10em;">
    <h1><strong>Privacy Policy</strong></h1>
    <p>We value your trust. In order to honour that trust, KidoLearn adheres to ethical standards in gathering, using, and
        safeguarding any information you provide. KidoLearn(operating under the brand name KidoLearn), is a leading
        edtech company, incorporated
        in India, for imparting learning. This privacy policy governs your use of the application ' KidoLearn&rsquo; &ndash;
        Feel The Learning Zeal' ('Application'), www. Keel.in ('Website') and the other associated applications,
        products, websites and services
        managed by the Company. Please read this privacy policy ('Policy') carefully before using the Application,
        Website, our services and products, along with the Terms of Use ('ToU') provided on the Application and the
        Website. Your use of the Website,
        Application, or services in connection with the Application, Website or products ('Services'), or registrations
        with us through any modes or usage of any products including through SD cards, tablets or other
        storage/transmitting device shall signify
        your acceptance of this Policy and your agreement to be legally bound by the same. If you do not agree with the
        terms of this Policy, do not use the Website, Application our products or avail any of our Services.</p>
    <p><strong>User Provided Information</strong></p>
    <p>The Application/Website/Services/products obtains the information you provide when you download and register for
        the Application or Services or products. When you register with us, you generally provide (a) your name, age,
        email address, location, phone
        number, password and your ward's educational interests; (b) transaction-related information, such as when you
        make purchases, respond to any offers, or download or use applications from us; (c) information you provide us
        when you contact us for help;
        (d) information you enter into our system when using the Application/Services/products, such as while asking
        doubts, participating in discussions and taking tests. The said information collected from the users could be
        categorized as &ldquo;Personal
        Information&rdquo;, &ldquo;Sensitive Personal Information&rdquo; and &ldquo;Associated Information&rdquo;.
        Personal Information, Sensitive Personal Information and Associated Information (each as individually defined
        under this Information Technology
        (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 (the
        &ldquo;Data Protection Rules&rdquo;)) shall collectively be referred to as 'Information' in this Policy. We may
        use the Information to contact
        you from time to time, to provide you with the Services, important information, required notices and marketing
        promotions. We will ask you when we need more information that personally identifies you (personal information)
        or allows us to contact
        you. We will not differentiate between who is using the device to access the Application, Website or Services or
        products, so long as the log in/access credentials match with yours. In order to make the best use of the
        Application/Website/Services/products
        and enable your Information to be captured accurately on the Application/Website/Services/products, it is
        essential that you have logged in using your own credentials. We will, at all times, provide the option to you
        to not provide the Personal Information
        or Sensitive Personal Information, which we seek from you. Further, you shall, at any time while using the
        Application/Services/products, also have an option to withdraw your consent given earlier to us to use such
        Personal Information or Sensitive
        Personal Information. Such withdrawal of the consent is required to be sent in writing to us at the contact
        details provided in this Policy below. In such event, however, the Company fully reserves the right not to allow
        further usage of the Application
        or provide any Services/products thereunder to you.</p>
    <p><strong>Automatically Collected Information</strong></p>
    <p>In addition, the Application/products/Services may collect certain information automatically, including, but not
        limited to, the type of mobile device you use, your mobile devices unique device ID, the IP address of your
        mobile device, your mobile operating
        system, the type of mobile Internet browsers you use, and information about the way you use the
        Application/Services/products. As is true of most Mobile applications, we also collect other relevant
        information as per the permissions that you provide.
        We use an outside credit card processing company to bill you for goods and services. These companies do not
        retain, share, store or use personally identifiable information for any other purpose.</p>
    <p><strong>Use of your Personal Information</strong></p>
    <p>We use the collected Information to analyse trends, to conduct research, to administer the Application/Services
        and products, to learn about each user's learning patterns and movements around the Application/Services and
        products and to gather demographic
        information and usage behaviour about our user base as a whole. Aggregated and individual, anonymized and
        non-anonymized data may periodically be transmitted to external service providers to help us improve the
        Application, products and our Services.
        We will share your information with third parties only in the ways that are described below in this Policy. We
        may use the individual data and behavior patterns combined with personal information to provide you with
        personalized content, and better
        your learning objectives. Third parties provide certain services which we may use to analyze the data and
        information to personalize, drive insights and help us better your experience or reach out to you with more
        value added applications, products,
        information and services. However, these third party companies do not have any independent right to share this
        information. We do not sell, trade or share your Information to any third party (except subsidiaries/affiliates
        of the Company for related
        offerings) unless, we have been expressly authorized by you either in writing or electronically to do so. We may
        at times provide aggregate statistics about our customers, traffic patterns, and related site information to
        reputable third parties,
        however this information when disclosed will be in an aggregate form and does not contain any of your Personally
        Identifiable Information. KidoLearn will occasionally send email notices, messages or contact you to communicate
        about our Services, products
        and benefits, as they are considered an essential part of the Services/products you have chosen. We may disclose
        Information:
    </p>
    <ul>
        <li>as required by law, such as to comply with a subpoena, or similar legal process;</li>
        <li>to enforce applicable ToU, including investigation of potential violations thereof;</li>
        <li>when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the
            safety of others, investigate fraud, address security or technical issues or respond to a government
            request;
        </li>
        <li>with our trusted services providers who work on our behalf, do not have an independent use of the
            information we disclose to them, and have agreed to adhere to the rules set forth in this Policy;</li>
        <li>to protect against imminent harm to the rights, property or safety of the Application/Website/
            Krisha&rsquo;s Excellence Education E Learning Private Limited or its users or the public as required or
            permitted by law;</li>
        <li>with third party service providers in order to personalize the Application/Website/Services/products for a
            better user experience and to perform behavioural analysis;</li>
        <li>Any portion of the Information containing personal data relating to minors provided by you shall be deemed
            to be given with the consent of the minor's legal guardian. Such consent is deemed to be provided by your
            registration with us.</li>
    </ul>
    <p><strong>Access to your Personal Information</strong></p>
    <p>We will provide you with the means to ensure that your Personal Information is correct and current. If you have
        filled out a user profile, we will provide an obvious way for you to access and change your profile from our
        Application/Services/Website/products.
        We adopt reasonable security measures to protect your password from being exposed or disclosed to anyone.</p>
    <p><strong>Cookies</strong></p>
    <p>We send cookies (small files containing a string of characters) to your computer, thereby uniquely identifying
        your browser. Cookies are used to track your preferences, help you login faster, and aggregated to determine
        user trends. This data is used
        to improve our offerings, such as providing more content in areas of greater interest to a majority of users.
        Most browsers are initially set up to accept cookies, but you can reset your browser to refuse all cookies or to
        indicate when a cookie is
        being sent. Some of our features and services may not function properly if your cookies are disabled.</p>
    <p><strong>Links</strong></p>
    <p>We may present links in a format that enables us to keep track of whether these links have been followed. We use
        this information to improve our customized content. Clicking on links may take you to sites outside our domain.
        We are not responsible for
        the privacy practices of other web sites. We encourage our users to be aware when they leave our site to read
        the privacy statements of each and every web site that collects personally identifiable information. This
        Privacy Policy applies solely to
        information collected by our Website.</p>
    <p><strong>Alerts</strong></p>
    <p>We may alert you by email or phone (through sms/call) to inform you about new service offerings of the Company
        and its subsidiaries/affiliates or other information which we feel might be useful for you, through the Company
        or its subsidiaries/affiliates.
    </p>
    <p><strong>Public Forums</strong></p>
    <p>When you use certain features on our website like the discussion forums and you post or share your personal
        information such as comments, messages, files, photos, will be available to all users, and will be in the public
        domain. All such sharing of information
        is done at your own risk. Please keep in mind that if you disclose personal information in your profile or when
        posting on our forums this information may become publicly available.</p>
    <p><strong>Security</strong></p>
    <p>We are concerned about safeguarding the confidentiality of your Information. We provide physical, electronic, and
        procedural safeguards to protect Information we process and maintain. For example, we limit access to this
        Information to authorized employees
        only who need to know that information in order to operate, develop or improve our
        Application/Services/products/Website. Please be aware that, although we endeavour to provide reasonable
        security for information we process and maintain, no security
        system can prevent all potential security breaches.
    </p>
    <p><strong>How Long Do We Retain User Data?</strong></p>
    <p>Currently, we plan to retain user data while an account is active and for at least three years afterward. We may
        alter this practice according to legal and business requirements. For example, we may lengthen the retention
        period for some data if needed
        to comply with law or voluntary codes of conduct. Unless otherwise prohibited, we may shorten the retention
        period for some types of data if needed to free up storage space.</p>
    <p><strong>Log information</strong></p>
    <p>When you access our Website, our servers automatically record information that your browser sends whenever you
        visit a website. These server logs may include information such as your web request, internet protocol address,
        browser type, browser language,
        the date and time of your request and one or more cookies that may uniquely identify your browser.
    </p>
    <p><strong>User communications</strong></p>
    <p>When you send an email or other communication to us, we may retain those communications in order to process your
        inquiries, respond to your requests and improve our Services.</p>
    <p><strong>Changes to this Statement</strong></p>
    <p>As the Company evolves, our privacy policy will need to evolve as well to cover new situations. You are advised
        to review this Policy regularly for any changes, as continued use is deemed approval of all changes.</p>
    <p><strong>Your Consent</strong></p>
    <p>We believe that, every user of our Application/Services/products/Website must be in a position to provide an
        informed consent prior to providing any Information required for the use of the
        Application/Services/products/Website. By registering with us,
        you are expressly consenting to our collection, processing, storing, disclosing and handling of your information
        as set forth in this Policy now and as amended by us. Processing, your information in any way, including, but
        not limited to, collecting,
        storing, deleting, using, combining, sharing, transferring and disclosing information, all of which activities
        will take place in India. If you reside outside India your information will be transferred, processed and stored
        in accordance with the
        applicable data protection laws of India.</p>
    <p><strong>Contact Information</strong></p>
    <p>Our Grievance Officer shall undertake all reasonable efforts to address your grievances at the earliest possible
        opportunity. You may contact us at: support@kidolearn.com, in case of any queries.</p>
    <p><strong>Refund & Cancellation</strong></p>
    <ul>
        <li>We want you to be satisfied, so all eligible courses purchased on KidoLearn can be refunded within 7 days,
            provided the request meets the guidelines in our refund policy.</li>
        <li>While our 7 days refund policy is to allow students to learn risk free.</li>
        <li>You can apply for refund through contact support.</li>
        <li>A Refund Request will be deemed valid only if it is made within 7 days from purchase.</li>
        <li>No Refund will be provided after 7 days from purchase.</li>
    </ul>
</div>