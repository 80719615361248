<!-- Service Start -->
<section class="section" id="services">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mb-5">
                    <h3 class="font-weight-normal text-dark">Our <span class="text-warning">Service</span></h3>
                    <p class="text-muted">Various formats of content are available for making your study easier.</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="service-box box-shwd service-warning" style="cursor: pointer;" (click)="gotoMaterials()">
                    <div class="mb-5" style="display: flex;justify-content: space-between;align-items: center;">
                        <i class="pe-7s-bookmarks service-icon"></i>
                        <div class="text-dark service-subtitle mb-4">
                            Free
                        </div>
                    </div>

                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Text Books</h5>
                    <p class="text-muted service-subtitle mb-4">Books with all relative content and containes example with great learning experience.</p>
                    <!-- <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box box-shwd service-warning" style="cursor: pointer;" (click)="gotoMaterials()">
                    <div class="mb-5" style="display: flex;justify-content: space-between;align-items: center;">
                        <i class="pe-7s-note2 service-icon"></i>
                        <div class="text-dark service-subtitle mb-4">
                            Free
                        </div>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Exam Paper</h5>
                    <p class="text-muted service-subtitle mb-4">Exam paper of all respective class and subject of the previous year and their solutioning.</p>
                    <!-- <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box box-shwd service-warning">
                    <div class="mb-5" style="display: flex;justify-content: space-between;align-items: center;">
                        <i class="pe-7s-display1 service-icon"></i>
                        <div class="text-dark service-subtitle mb-4">
                            On App Only
                        </div>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">E-Learning</h5>
                    <p class="text-muted service-subtitle mb-4">E-Learning leacture recording of the best faculty gathered together to teach you best.</p>
                    <!-- <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box box-shwd service-warning">
                    <div class="mb-5" style="display: flex;justify-content: space-between;align-items: center;">
                        <i class="pe-7s-play service-icon"></i>
                        <div class="text-dark service-subtitle mb-4">
                            On App Only
                        </div>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Videos</h5>
                    <p class="text-muted service-subtitle mb-4">Great Collections of videos of all subject topics with best explanatory examples and content.</p>
                    <!-- <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box box-shwd service-warning">
                    <div class="mb-5" style="display: flex;justify-content: space-between;align-items: center;">
                        <i class="pe-7s-light service-icon"></i>
                        <div class="text-dark service-subtitle mb-4">
                            On App Only
                        </div>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Live Lectures</h5>
                    <p class="text-muted service-subtitle mb-4">Live Lectures scheduled with our top faculty for better understanding and problem solving.</p>
                    <!-- <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="service-box box-shwd service-warning">
                    <div class="mb-5" style="display: flex;justify-content: space-between;align-items: center;">
                        <i class="pe-7s-cup service-icon"></i>
                        <div class="text-dark service-subtitle mb-4">
                            On App Only
                        </div>
                    </div>
                    <h5 class="service-title text-dark font-weight-normal pt-1 mb-4">Quiz Assessment</h5>
                    <p class="text-muted service-subtitle mb-4">Assessment for each topic and subjet of every class will be conducted to see progress of students.</p>
                    <!-- <a href="#" class="read-more-icon"><span class="right-icon">&#8594;</span></a> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Service End -->