<div class="modal-header">
    <h4 class="modal-title" id="modal-title" style="font-size: large;">KEEEL FRANCHISE PARTNER PROGRAM</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <div class="row">
        <div class="col-md-12" style="text-decoration: underline;color: blue;text-align: end;font-size: 12px;">
            <span (click)="openLink()" style="cursor: pointer;">
                Click Here for More Details
            </span>
        </div>
    </div>
    <form [formGroup]="addDeatilOfFranchiseForm" name="addDeatilOfFranchiseForm">
        <div class="row">
            <div class="col-md-12">
                <label for="name" class="label required label-bold">Name</label>
                <input type="text" placeholder="Name" id="name" formControlName="name" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="email" class="label required label-bold">Email</label>
                <input type="text" placeholder="Email" id="email" formControlName="email" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="mobileNo" class="label required label-bold">Mobile Number</label>
                <input type="text" placeholder="Mobile Number" id="mobileNo" formControlName="mobileNo" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="franchiseType" class="label required label-bold">Franchise Type</label><br>
                <div class="row">
                    <div class="col-md-6">
                        <input type="radio" id="ZONEFRANCHISE" name="franchiseType" value="ZONE FRANCHISE" formControlName="franchiseType">   <label for="ZONEFRANCHISE">ZONE FRANCHISE </label><br>
                    </div>
                    <div class="col-md-6">
                        <input type="radio" id="STATEFRANCHISE" name="franchiseType" value="STATE FRANCHISE" formControlName="franchiseType">   <label for="STATEFRANCHISE">STATE FRANCHISE </label><br>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <input type="radio" id="DISTRICTFRANCHISE" name="franchiseType" value="DISTRICT FRANCHISE" formControlName="franchiseType">   <label for="DISTRICTFRANCHISE">DISTRICT FRANCHISE
                        </label><br>
                    </div>
                    <div class="col-md-6">
                        <input type="radio" id="REGIONFRANCHISE" name="franchiseType" value="REGION FRANCHISE" formControlName="franchiseType">   <label for="REGIONFRANCHISE">REGION FRANCHISE
                        </label><br>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <input type="radio" id="SCHOOLFRANCHISE" name="franchiseType" value="SCHOOL FRANCHISE" formControlName="franchiseType">   <label for="SCHOOLFRANCHISE">SCHOOL FRANCHISE
                        </label><br>
                    </div>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="amountSpent" class="label required label-bold">AMOUNT WHICH YOU CAN INVEST ?</label><br>
                <div class="row">
                    <div class="col-md-6">

                        <input type="radio" id="1-2lakh" name="amountSpent" value="1 - 2 Lakh" formControlName="amountSpent">   <label for="1-2lakh">1 - 2 Lakh </label><br>

                    </div>
                    <div class="col-md-6">
                        <input type="radio" id="2-5lakh" name="amountSpent" value="2 - 5 Lakh" formControlName="amountSpent">   <label for="2-5lakh">2 - 5 Lakh </label><br>


                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">

                        <input type="radio" id="5-10lakh" name="amountSpent" value="5 - 10 Lakh" formControlName="amountSpent">   <label for="5-10lakh">5 - 10 Lakh </label><br>
                    </div>
                    <div class="col-md-6">
                        <input type="radio" id="10-15lakh" name="amountSpent" value="10 - 15 Lakh" formControlName="amountSpent">   <label for="10-15lakh">10 - 15 Lakh </label><br>

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">

                        <input type="radio" id="50-100lakh" name="amountSpent" value="50 - 100 Lakh" formControlName="amountSpent">   <label for="50-100lakh">50 - 100 Lakh </label><br>
                    </div>
                    <div class="col-md-6">
                        <input type="radio" id="above1Cr" name="amountSpent" value="Above 1 Crore" formControlName="amountSpent">   <label for="above1Cr">Above 1 Crore </label><br>

                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="zone" class="label required label-bold">Zone / State / District / Region / School, You Want
                    to Apply?
                </label>
                <input type="text" placeholder="Enter Zone" id="zone" formControlName="zone" class="input-style">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label for="note" class="label required label-bold">NOTE - ALL FRANCHISE FEES IS NON REFUUNDABLE. and
                    you should
                    have minimum 100 square feet office. </label>
                <!-- <input type="text" placeholder="Enter Detail" id="note" formControlName="note" class="input-style"> -->
                <div class="row">
                    <div class="col-md-12">

                        <input type="radio" id="yes" name="note" value="YES" formControlName="note">   <label for="yes">YES</label><br>

                    </div>
                    <div class="col-md-12">
                        <input type="radio" id="no" name="note" value="NO" formControlName="note">   <label for="no">NO</label><br>


                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="save">
                    <input type="submit" [ngClass]="{'save-btn-disabled':addDeatilOfFranchiseForm.invalid ,'save-btn':addDeatilOfFranchiseForm.valid }" value="{{'Save'}}" [disabled]="addDeatilOfFranchiseForm.invalid " (click)="registerUserIfNotExist()" />

                </div>
            </div>
        </div>
    </form>

</div>