import { Component, OnInit, Type } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import * as firebase from 'firebase';

@Component({
  selector: 'app-login-signup-dialog',
  templateUrl: './login-signup-dialog.component.html',
  styleUrls: ['./login-signup-dialog.component.css'],
})
export class LoginSignupDialogComponent implements OnInit {
  isloading: boolean = false;
  isLogin: boolean = true;
  isValidUser: any;
  showStandardAsk: boolean = false;
  userDetails: any;

  mobileNoControl = new FormControl('', [Validators.required]);
  boardNameControl = new FormControl('Select Board', [Validators.required]);
  classNameControl = new FormControl('Select Class', [Validators.required]);

  addExtraDetailForm: FormGroup = new FormGroup({
    mobileNo: this.mobileNoControl,
    boardName: this.boardNameControl,
    className: this.classNameControl,
  });
  listOfClass: any = [
    {
      label: 'VI',
    },
    {
      label: 'VII',
    },
    {
      label: 'VIII',
    },
    {
      label: 'Xi',
    },
    {
      label: 'X',
    },
    {
      label: 'XI',
    },
    {
      label: 'XII',
    },
  ];
  verifiedUser: any;
  constructor(
    public modal: NgbActiveModal,
    private authService: SocialAuthService,
    private _modalService: NgbModal,
    public as: AppService,
    private formBuilder: FormBuilder,
    private firestore: AngularFirestore,
    private toastr: ToastrService
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('keeelWebUser'));
  }

  ngOnInit(): void {
    this.addExtraDetailForm = this.formBuilder.group({
      mobileNo: this.mobileNoControl,
      boardName: this.boardNameControl,
      className: this.classNameControl,
    });
    window['AppleID'].auth.init({
      clientId: 'com.keeel.keeeleducation', // This is the service ID we created.
      scope: 'name email', // To tell apple we want the user name and emails fields in the response it sends us.
      redirectURI: 'https://keeel.in', // As registered along with our service ID
      state: 'origin:web', // Any string of your choice that you may use for some logic. It's optional and you may omit it.
      usePopup: true, // Important if we want to capture the data apple sends on the client side.
    });
    // console.log(window['AppleID']);
  }
  signInWithGoogle(): void {
    try {
      this.authService
        .signIn(GoogleLoginProvider.PROVIDER_ID)
        .then((verifiedUser) => {
          console.log(verifiedUser);
          this.authService.authState.subscribe((user) => {
            console.log(user);
          });
          console.log(); //this log 'null'

          if (verifiedUser) {
            this.verifiedUser = verifiedUser;
            const data = this.firestore.collection('users', (ref) =>
              ref
                .where('email', '==', verifiedUser['email'])
                .where('appId', '==', 'pW2kNatlvKxzJRhm9QKR')
            );
            data.get().subscribe(async (val) => {
              if (val.docs.length > 0) {
                this.showStandardAsk = false;
                this.modal.close(verifiedUser);
              } else {
                this.showStandardAsk = true;
              }
            });
          } else {
            this.showStandardAsk = false;
            this.modal.close(null);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  onBoardSelectionChange(event: any) {
    // console.log(event.target.value);
    let board = event;
    if (board == 'OTHERBOARD') {
      this.listOfClass = [
        {
          label: 'XI',
        },
        {
          label: 'XII',
        },
      ];
    } else {
      this.listOfClass = [
        {
          label: 'VI',
        },
        {
          label: 'VII',
        },
        {
          label: 'VIII',
        },
        {
          label: 'Xi',
        },
        {
          label: 'X',
        },
        {
          label: 'XI',
        },
        {
          label: 'XII',
        },
      ];
    }
  }
  getTimePlusXDays(dateTime: Date, x: number) {
    return dateTime.setDate(dateTime.getDate() + x);
  }
  async registerUserIfNotExist() {
    if (
      this.addExtraDetailForm.get('boardName')?.value == 'Select Board' ||
      this.addExtraDetailForm.get('className')?.value == 'Select Class'
    ) {
      return false;
    }
    let planValidTill = new Date();
    this.getTimePlusXDays(planValidTill, 365);
    const newId = this.firestore.createId();
    let toInsert = {
      displayName: this.verifiedUser['name'],
      appId: 'pW2kNatlvKxzJRhm9QKR',
      email: this.verifiedUser['email'],
      duration: '',
      mobileNo: this.addExtraDetailForm.get('mobileNo')?.value,
      photoURL: this.verifiedUser['photoUrl'],
      referral: Math.floor(100000 + Math.random() * 900000),
      uid: newId,
      listOfTransactionId: [],
      board: this.addExtraDetailForm.get('boardName')?.value,
      standard: this.addExtraDetailForm.get('className')?.value,
      plan: 'SILVER',
      planValidTill: planValidTill.toISOString(),
      referralCount: 0,
    };
    console.log(await firebase.default.auth().currentUser);
    await this.firestore
      .collection('users')
      .doc(newId)
      .set(toInsert)
      .then((val) => {
        console.log(val);
        this.modal.close({
          email: this.verifiedUser['email'],
          toInsert: toInsert,
        });
      });
  }
  async signInWithApple() {
    const response = await window['AppleID'].auth.signIn();
    console.log(response);
  }
  showSuccessToaster(message, title) {
    this.toastr.error(message, title);
  }
  showErrorToaster(message, title) {
    this.toastr.error(message, title);
  }
}
